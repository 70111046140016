const { default: axios } = require("axios");

const serviceInstance = axios.create({
  baseURL: "https://designgost.com/api",
  headers: {
    "Cache-Control": "no-cache",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
  maxBodyLength: Infinity,
  withCredentials: false,
});

const login = (email, password) =>
  serviceInstance
    .post(`/auth/login`, {
      email: email,
      password: password,
    })
    .then((response) => {
      serviceInstance.defaults.headers.common["Authorization"] =
        response.data.access_token;
      return response.data;
    })
    .catch((error) => {
      if (error.response && error.response.status === 404) {
        return `\u2014`;
      }
    });

const register = (name, username, email, password, confirmPassword) =>
  serviceInstance
    .post(`/auth/register`, {
      name: name,
      username: username,
      email: email,
      password: password,
      confirmPassword: confirmPassword,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response && error.response.status === 404) {
        return `\u2014`;
      }
    });

const getPodcasts = () =>
  serviceInstance
    .get(`/podcast`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response && error.response.status === 404) {
        return `\u2014`;
      }
    });

const getPodcastDetail = (slug) =>
  serviceInstance
    .get(`/podcast/${slug}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response && error.response.status === 404) {
        return `\u2014`;
      }
    });

const getVideos = () =>
  serviceInstance
    .get(`/tutorial`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response && error.response.status === 404) {
        return `\u2014`;
      }
    });

const getVideoDetail = (slug) =>
  serviceInstance
    .get(`/tutorial/${slug}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response && error.response.status === 404) {
        return `\u2014`;
      }
    });

const getExploreDropdown = () =>
  serviceInstance
    .get(`/discover`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response && error.response.status === 404) {
        return `\u2014`;
      }
    });

const getBlogs = () =>
  serviceInstance
    .get(`/blog`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response && error.response.status === 404) {
        return `\u2014`;
      }
    });

const getBlogDetail = (slug) =>
  serviceInstance
    .get(`/blog/${slug}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response && error.response.status === 404) {
        return `\u2014`;
      }
    });

const getBlogCategories = () =>
  serviceInstance
    .get(`/article-category`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response && error.response.status === 404) {
        return `\u2014`;
      }
    });

const getUsers = (query) =>
  serviceInstance
    .get(`/mentors${"?" + query}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response && error.response.status === 404) {
        return `\u2014`;
      }
    });

const getUserDetail = (slug) =>
  serviceInstance
    .get(`/member/${slug}`)
    .then((response) => {
      const mergedJSON = Object.assign(
        {},
        response.data.rowsProfileOwner[0],
        response.data.about
      );
      mergedJSON.projects = response.data.projects;
      mergedJSON.allMentees = response.data.allMentees;
      mergedJSON.currentMentees = response.data.currentMentees;
      mergedJSON.counts = response.data.counts;
      return mergedJSON;
    })
    .catch((error) => {
      if (error.response && error.response.status === 404) {
        return `\u2014`;
      }
    });

const getProjects = (query) =>
  serviceInstance
    .get(`/projects${query}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response && error.response.status === 404) {
        return `\u2014`;
      }
    });

const getProjectDetail = (slug) =>
  serviceInstance
    .get(`/project/project_id/${slug}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response && error.response.status === 404) {
        return `\u2014`;
      }
    });

module.exports = {
  login,
  register,
  getPodcasts,
  getPodcastDetail,
  getVideos,
  getVideoDetail,
  getExploreDropdown,
  getBlogs,
  getBlogDetail,
  getBlogCategories,
  getUsers,
  getUserDetail,
  getProjects,
  getProjectDetail,
};
