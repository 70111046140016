
import { Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import useIsMobile from "../../public/is-Mobile";
import trLocale from "date-fns/locale/tr";
import Categories from "../../data/categories";

const BlogCard = (props) => {
  const isMobile = useIsMobile();

  const element = props.element ?? {
    cover_img: "https://trtrades.com/wp-content/uploads/2021/09/Gray-Box.jpeg",
    slug: "dunyanin-en-ilham-verici-online-tasarim-ve-sanat-platformlari",
    name: "Dünyanın En İlham Verici Online Tasarım ve Sanat Platformları",
    title: "Dünyanın En İlham Verici Online Tasarım ve Sanat Platformları",
  };

  return (
    <Link to={`/blog/${element.url}`}>
      <Row style={{ gap: 8 }}>
        <Image
          src={element.image}
          style={{
            width: "100%",
            aspectRatio: "1.5",
            borderRadius: "8px",
            objectFit: "cover",
          }}
        />

        <Row className="f-label" style={{ display: "inline", marginTop: 8 }}>
          <span>{element.category} •</span>
          <span> </span>
          <span style={{ color: "var(--neutral500)" }}>{element.date}</span>
        </Row>

        <Row className="f-label-l" style={{ fontWeight: 700 }}>
          {element.title}
        </Row>
      </Row>
    </Link>
  );
};

export default BlogCard;
