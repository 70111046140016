import { format } from "date-fns";
import { useEffect, useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import DGButton from "../../../components/Buttons/DGButton";
import BlogCard from "../../../components/Cards/BlogCard";
import Categories from "../../../data/categories";
import useIsMobile from "../../../public/is-Mobile";
import { getBlogDetail } from "../../../Service";
import trLocale from "date-fns/locale/tr";

import blogs from "../../../data/blogs.json";

const BlogDetail = () => {
  const isMobile = useIsMobile();

  const slug = window.location.pathname.replaceAll("/blog/", "");

  const [blogData, setBlogData] = useState(null);

  return (
    <div>
      <Container
        style={{
          padding: isMobile ? "20px 20px 30px" : "50px 0px 100px",
          maxWidth: "750px",
        }}
      >
        <div className={isMobile ? "f-h3" : "f-h2"}>{blogData?.title}</div>

        <InfoView data={blogData} />

        <Image
          src={blogData?.cover_img}
          style={{ width: "100%", marginBottom: 40 }}
        />

        <div
          className="explore-content"
          dangerouslySetInnerHTML={{
            __html: blogs.filter((element) => element.url == slug)[0].content,
          }}
        />
      </Container>

      {/* <PageFooter /> */}
    </div>
  );
};

const InfoView = (props) => {
  const isMobile = useIsMobile();

  const categoryName = Categories.blogCategories?.find(
    (element) => element.article_category_id == props.data?.design_category_id
  )?.name;

  const createdAt = props.data?.created_at;

  return (
    <Row style={{ margin: isMobile ? "16px 0" : "22px 0 40px 0" }}>
      <Col>
        <Row style={{ alignItems: "center" }}>
          <Image
            roundedCircle={true}
            src="https://trtrades.com/wp-content/uploads/2021/09/Gray-Box.jpeg"
            style={{ width: "30px", height: "30px", marginRight: "8px" }}
          />
          <Col xs className="f-paragraph">
            Hakan Ertan
          </Col>
        </Row>
      </Col>

      <Col
        xs={isMobile ? 12 : "auto"}
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          justifyContent: isMobile ? "flex-start" : "flex-end",
          marginTop: isMobile ? "16px" : "0px",
        }}
      >
        <Link
          to={"/blog?category=" + categoryName}
          className="f-label"
          style={{ fontWeight: 700, textDecoration: "underline" }}
        >
          {categoryName}
        </Link>
        {createdAt ?? "" !== "" ? (
          <div className="f-label-s">
            •{" "}
            {format(new Date(createdAt), "dd MMMM yyyy", {
              locale: trLocale,
            })}
          </div>
        ) : null}
      </Col>
    </Row>
  );
};

const PageFooter = () => {
  const isMobile = useIsMobile();

  return (
    <div
      style={{
        background: "#F0EDF6",
        width: "100%",
        padding: isMobile ? "30px 0px" : "100px 0px",
      }}
    >
      <Container>
        <div
          className="f-h3"
          style={{ padding: isMobile ? "0px 20px" : "0px" }}
        >
          Bu blog yazılarını da beğenebilirsin
        </div>

        {isMobile ? (
          <Row
            className="scroll-view-hidden"
            style={{
              gap: "12px",
              padding: "17px 20px 30px",
              flexDirection: "row",
              flexWrap: "nowrap",
              overflow: "scroll",
            }}
          >
            <BlogCard
              image="https://trtrades.com/wp-content/uploads/2021/09/Gray-Box.jpeg"
              width="160px"
            />
            <BlogCard
              image="https://trtrades.com/wp-content/uploads/2021/09/Gray-Box.jpeg"
              width="160px"
            />
            <BlogCard
              image="https://trtrades.com/wp-content/uploads/2021/09/Gray-Box.jpeg"
              width="160px"
            />
          </Row>
        ) : (
          <Row
            className="scroll-view-hidden"
            style={{
              display: "grid",
              gridTemplateColumns: "auto auto auto",
              gap: "30px",
              margin: "35px 0px 70px 0px",
            }}
          >
            {[...Array(3)].map((x, i) => (
              <BlogCard
                image="https://trtrades.com/wp-content/uploads/2021/09/Gray-Box.jpeg"
                key={i}
              />
            ))}
          </Row>
        )}

        <div style={{ display: "flex", justifyContent: "center" }}>
          <Link to="/blog">
            <DGButton variant="dg-primary" size="medium">
              Blog Sayfasına Dön
            </DGButton>
          </Link>
        </div>
      </Container>
    </div>
  );
};

export default BlogDetail;
