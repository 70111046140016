import { useEffect } from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import Footer from "../components/Footer/Footer.jsx";
import Header from "../components/Header/Header.jsx";
import useIsMobile from "../public/is-Mobile.jsx";

const Layout = ({ children }) => {
  const location = useLocation();
  const isMobile = useIsMobile();

  const [hideHeaderFooter, setHideHeaderFooter] = useState(false);

  const [isDark, setIsDark] = useState(
    isMobile
      ? location.pathname === "/"
      : location.pathname === "/" ||
          location.pathname.includes("/ustalar/") ||
          location.pathname.includes("/ciraklar/") ||
          location.pathname.includes("/profile")
  );

  useEffect(() => {
    setIsDark(
      isMobile
        ? location.pathname === "/"
        : location.pathname === "/" ||
            location.pathname.includes("/ustalar/") ||
            location.pathname.includes("/ciraklar/") ||
            location.pathname.includes("/profile")
    );
    setHideHeaderFooter(
      location.pathname.includes("/choose-signup") ||
        location.pathname.includes("/signup") ||
        location.pathname.includes("/login-otp") ||
        location.pathname.includes("/login")
    );
  }, [location.pathname, isMobile]);

  return (
    <>
      {hideHeaderFooter ? null : <Header variant={isDark ? "dark" : "light"} />}

      <main>{children}</main>

      {hideHeaderFooter ? null : <Footer />}
    </>
  );
};

export default Layout;
