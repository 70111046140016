import { useState, useEffect } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import useIsMobile from "../../../public/is-Mobile";
import { getPodcastDetail } from "../../../Service";

const PodcastDetail = (props) => {
  const isMobile = useIsMobile();

  const location = useLocation();
  const slug = window.location.pathname.replaceAll("/podcast/", "");

  const fetchData = async () => {
    try {
      const request = await getPodcastDetail(slug);
      setPodcastData(request);
    } catch (error) {
      console.error("Error fetching assets:", error.message);
    }
  };

  const [podcastData, setPodcastData] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [location.pathname]);

  return (
    <div>
      <Container
        style={{
          padding: isMobile ? "20px 20px 30px" : "50px 0px 100px",
          maxWidth: "750px",
        }}
      >
        <div className={isMobile ? "f-h3" : "f-h2"}>{podcastData?.name}</div>
        <Row style={{ margin: isMobile ? "16px 0" : "22px 0 40px 0" }}>
          <Col>
            <Row style={{ alignItems: "center" }}>
              <Image
                roundedCircle={true}
                src="https://trtrades.com/wp-content/uploads/2021/09/Gray-Box.jpeg"
                style={{ width: "30px", height: "30px", marginRight: "8px" }}
              />
              <Col xs className="f-paragraph">
                {podcastData?.person}
              </Col>
            </Row>
          </Col>
          <Col
            xs={isMobile ? 12 : "auto"}
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              justifyContent: isMobile ? "flex-start" : "flex-end",
              marginTop: isMobile ? "16px" : "0px",
            }}
          >
            <div className="f-label" style={{ fontWeight: 700 }}>
              Tasarım İlkeleri
            </div>
            <div className="f-label-s">• 19 Haziran 2020</div>
          </Col>
        </Row>

        <Row style={{ gap: 40 }}>
          <div
            className="podcast-embed-container"
            dangerouslySetInnerHTML={{ __html: podcastData?.embed }}
          />
          <div dangerouslySetInnerHTML={{ __html: podcastData?.description }} />
          <div
            dangerouslySetInnerHTML={{ __html: podcastData?.spotify_embed }}
          />
        </Row>
      </Container>
      {/* <div
        style={{
          background: "#F0EDF6",
          width: "100%",
          padding: isMobile ? "30px 0px" : "100px 0px",
        }}
      >
        <Container>
          <div
            className="f-h3"
            style={{ padding: isMobile ? "0px 20px" : "0px" }}
          >
            Bu blog yazılarını da beğenebilirsin
          </div>

          {isMobile ? (
            <Row
              className="scroll-view-hidden"
              style={{
                gap: "12px",
                padding: "17px 20px 30px",
                flexDirection: "row",
                flexWrap: "nowrap",
                overflow: "scroll",
              }}
            >
              <BlogCard
                image="https://trtrades.com/wp-content/uploads/2021/09/Gray-Box.jpeg"
                width="160px"
              />
              <BlogCard
                image="https://trtrades.com/wp-content/uploads/2021/09/Gray-Box.jpeg"
                width="160px"
              />
              <BlogCard
                image="https://trtrades.com/wp-content/uploads/2021/09/Gray-Box.jpeg"
                width="160px"
              />
            </Row>
          ) : (
            <Row
              className="scroll-view-hidden"
              style={{
                display: "grid",
                gridTemplateColumns: "auto auto auto",
                gap: "30px",
                margin: "35px 0px 70px 0px",
              }}
            >
              {[...Array(3)].map((x, i) => (
                <BlogCard
                  image="https://trtrades.com/wp-content/uploads/2021/09/Gray-Box.jpeg"
                  key={i}
                />
              ))}
            </Row>
          )}

          <div style={{ display: "flex", justifyContent: "center" }}>
            <Link to="/blog">
              <DGButton variant="dg-primary" size="medium">
                Blog Sayfasına Dön
              </DGButton>
            </Link>
          </div>
        </Container>
      </div> */}
    </div>
  );
};

export default PodcastDetail;
