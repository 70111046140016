import React from "react";
import { Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import DGButton from "../../components/Buttons/DGButton";
import DGInput from "../../components/Inputs/DGInput";

// Utils
import RegisterLayout from "../../layout/RegisterLayout";
import { login } from "../../Service";

const Login = () => {
  const navigate = useNavigate();

  const mockForm = [
    {
      label: "Ad - Soyad",
      input: "name",
      placeholder: "",
      settings: {
        required: true,
      },
    },
    {
      label: "E-posta adresi",
      input: "email",
      placeholder: "",
      settings: {
        required: true,
      },
    },
    {
      label: "Kullanıcı Adı",
      input: "username",
      placeholder: "",
      settings: {
        required: true,
      },
    },
    {
      label: "Parola",
      input: "password",
      placeholder: "6+ karakter",
      settings: {
        required: true,
        minLength: 6,
      },
    },
    {
      label: "Parola Onayla",
      input: "checkPassword",
      placeholder: "",
      settings: {
        required: true,
      },
    },
  ];

  const onSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target),
      formDataObj = Object.fromEntries(formData.entries());

    loginWith(formDataObj.email, formDataObj.password);
  };

  const loginWith = async (email, password) => {
    try {
      const request = await login(email, password);
    } catch (error) {
      console.error("Error fetching assets:", error.message);
    }
  };

  return (
    <RegisterLayout isLogin={true}>
      <Row style={{ maxWidth: 440, gap: 45 }}>
        <Row style={{ gap: 20 }}>
          <h5 className="f-h5" style={{ fontWeight: 700 }}>
            Giriş Yap
          </h5>
          <div className="f-label">
            Aşağıdaki kutulara bilgilerinizi giriniz
          </div>
        </Row>

        <Form
          onSubmit={onSubmit}
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 18,
          }}
        >
          <DGInput name="email" title="E-posta adresi" />
          <DGInput name="password" title="Parola" type="password" />
          <Row style={{ justifyContent: "space-between" }}>
            <DGButton
              variant="dg-primary"
              size="medium"
              style={{ width: "fit-content", marginTop: 16 }}
              type="submit"
            >
              Giriş Yap
            </DGButton>

            <Link className="f-label" style={{ width: "auto" }}>
              Şifreni mi unuttun?
            </Link>
          </Row>
        </Form>
      </Row>
    </RegisterLayout>
  );
};

export default Login;
